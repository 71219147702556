import upperFirst from 'lodash/upperFirst';
import getCategoryField from './queries/news/getCategoryField';

const getCategorySubjectType = (
  prefix: string,
  hasLocalNews: boolean,
) => upperFirst(
  getCategoryField(prefix, hasLocalNews),
);

export default getCategorySubjectType;
