import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Headline3 from '../Typography/Heading/Headline3';
import { Breadcrumbs } from '../Breadcrumb';
import { Grid, GridCell } from '../Grid';
import breakpoints from '../Theme/breakpoints';

const Title = styled(Headline3)`
  text-align: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  word-break: break-word;
  justify-content: center;

  @media (min-width: ${breakpoints.sm}px) {
    height: auto;
    justify-content: flex-start;
    margin: 0;
  }
`;

const CategoryPage = ({
  breadcrumbs,
  title,
  children,
}) => (
  <Grid>
    <GridCell padding="0 20px 0">
      <Breadcrumbs
        crumbs={breadcrumbs}
      />
    </GridCell>
    <GridCell padding="32px">
      <Title tag="h1">
        {title}
      </Title>
    </GridCell>
    <GridCell container spacing={4}>
      {children}
    </GridCell>
  </Grid>
);

CategoryPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.node).isRequired,
  children: PropTypes.node,
};

CategoryPage.defaultProps = {
  children: undefined,
};

export default CategoryPage;
