import React from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { useIntl } from 'react-intl';
import CategoryPageLayout from '@fuww/library/src/Layouts/CategoryPage';

import Articles from '../components/News/ArticleFeed';
import Head from '../components/Head';
import HomeLink from '../components/HomeLink';
import LinkAndAnchor from '../components/LinkAndAnchor';
import categoryHeaderQuery
from '../lib/queries/news/categoryHeaderQuery.graphql';
import getCategoryField from '../lib/queries/news/getCategoryField';
import getCategorySubjectType from '../lib/getCategorySubjectType';
import throw404 from '../lib/throw404';
import withQuery from '../lib/withQuery';
import messages from '../lib/messages.mjs';
import getCategoryParameters from '../lib/getCategoryParameters';
import getCategoryBreadcrumb from '../lib/getCategoryBreadcrumb';
import { useSiteContext } from '../components/SiteContext';
import articlesConnectionQuery
from '../lib/queries/news/articlesConnectionQuery.graphql';
import getNewsboardFieldVariables
from '../lib/queries/getNewsboardFieldVariables';
import { articleListImageConfigurations } from '../lib/imageConfigurations';
import getConnectionField from '../lib/queries/getConnectionField';
import withApollo from '../lib/apollo';
import getCategoryTitle from '../lib/getCategoryTitle';
import { translateCategoryParameter } from '../lib/categories';
import redirectToNewsDomain from '../lib/redirectToNewsDomain';

const LIMIT = 12;

const ArticleList = ({
  data, field, variables, articlesField, categoryVariables, loading, fetchMore,
}) => (
  <Articles
    categoryVariables={categoryVariables}
    variables={variables}
    fetchMore={fetchMore}
    articles={data[field]}
    data={data}
    field={field}
    articlesField={articlesField}
    loading={loading}
    preloadFirst
  />
);

ArticleList.propTypes = {
  data: PropTypes.shape({}),
  variables: PropTypes.shape({}).isRequired,
  categoryVariables: PropTypes.shape({}),
  fetchMore: PropTypes.func,
  field: PropTypes.string.isRequired,
  articlesField: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

ArticleList.defaultProps = {
  categoryVariables: undefined,
  data: undefined,
  fetchMore: undefined,
  articlesField: null,
};

const ArticlesWithQuery = withQuery(ArticleList);

const Category = ({
  data,
  field,
  children,
}) => {
  const intl = useIntl();
  const { prefix, hasLocalNews, locale } = useSiteContext();

  const category = data[field];
  if (!category) return throw404();

  const { id } = category;
  const categoryTitle = capitalize(
    getCategoryTitle(category, hasLocalNews, intl),
  );
  const pageTitle = intl.formatMessage(messages.categoryTitle, {
    title: categoryTitle,
  });

  const breadcrumbs = [
    <HomeLink />,
    getCategoryBreadcrumb(category, hasLocalNews, intl, locale),
    <LinkAndAnchor
      route="category"
      params={getCategoryParameters(category)}
    >
      {categoryTitle}
    </LinkAndAnchor>,
  ].filter(Boolean);
  return (
    <>
      <Head
        title={pageTitle}
        description={pageTitle}
        subjectType={getCategorySubjectType(prefix, hasLocalNews)}
        subjectId={id}
      />
      <CategoryPageLayout
        title={pageTitle}
        breadcrumbs={breadcrumbs}
      >
        {children}
      </CategoryPageLayout>
    </>
  );
};

Category.propTypes = {
  data: PropTypes.shape({}),
  field: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Category.defaultProps = {
  data: {},
};

const CategoryWithQuery = withQuery(Category);

export const CategoryPage = ({ router }) => {
  const { prefix, hasLocalNews, newsboard } = useSiteContext();
  const { query } = router;

  const categoryPath = translateCategoryParameter(query.categoryPath);
  const categoryField = getCategoryField(prefix, hasLocalNews);
  const articlesConnectionField = getConnectionField(prefix, hasLocalNews);
  const newsboardFieldVariables = getNewsboardFieldVariables(
    prefix,
    hasLocalNews,
  );

  const categoryConnectionVariables = {
    ...newsboardFieldVariables,
    pageviews: false,
    categoryPathPrefix: categoryPath,
    first: LIMIT,
    newsboards: [newsboard],
    sort: 'CREATED_AT',
    statuses: ['PUBLISHED'],
    imageConfigurations: articleListImageConfigurations,
  };

  return (
    <CategoryWithQuery
      query={categoryHeaderQuery}
      field={categoryField}
      variables={{
        ...newsboardFieldVariables,
        path: categoryPath,
      }}
      errorMessage="Error loading news articles for this category"
    >
      <ArticlesWithQuery
        query={articlesConnectionQuery}
        field={articlesConnectionField}
        variables={categoryConnectionVariables}
        errorMessage="Error loading news articles for this category"
      />
    </CategoryWithQuery>
  );
};

CategoryPage.propTypes = {
  router: PropTypes.shape({
    query: PropTypes.shape({
      categoryPath: PropTypes.string,
    }),
  }),
};

CategoryPage.defaultProps = {
  router: {},
};

CategoryPage.getInitialProps = ({
  req, res, query, asPath,
}) => {
  redirectToNewsDomain(asPath)({ req, res, query });
  return {};
};

export default withApollo(withRouter(CategoryPage));
